import {ImportField} from "./importField";

export class Import {
    privateKey = "";
    id = null;
    name = "";
    select = false;
    description = "";
    use = "Ecriture";
    fields: ImportField[] = [];
    options = {
        nbLigneToSkipAtBegin: 0,
        nbLigneToSkipAtEnd: 0,
    };

    constructor(data: any = null) {
        if (data !== null) {
            this.id = data.id;
            if (data.hasOwnProperty("privateKey")) {
                this.privateKey = data.privateKey;
            }
            if (data.hasOwnProperty("name")) {
                this.name = data.name;
            }
            if (data.hasOwnProperty("description")) {
                this.description = data.description;
            }
            if (data.hasOwnProperty("use")) {
                this.use = data.use;
            }
            this.fields = [];
            if (data.hasOwnProperty("fields")) {
                for (let i = 0; i < data.fields.length; i++) {
                    const field = new ImportField(data.fields[i]);
                    this.fields.push(field);
                }
            }
            if (data.hasOwnProperty("options")) {
                this.options = Object.assign(this.options, data.options);
            }
        }
    }
}
