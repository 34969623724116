export class Item {
    id: number = null;
    privateKey = ''
    createdAt: string = null;
    updatedAt: string = null;
    type: string = "";
    description: string = "";
    substractedAccounts: any[] = [];
    name: string = "";
    operation: string = "";
    addedAccounts: any[] = [];
    includeInSubGraphique: boolean = false;
    isVisible: boolean = false;
    showAsGraphic: boolean = false;
    previousPeriod: string = "";
    isPercentReference2: boolean = false;
    select: boolean = false;
    /*****/
    color = "";

    constructor(data: any = null) {
        if (data !== null) {
            this.id = data.id;
            this.privateKey = data.privateKey;
            this.createdAt = data.createdAt;
            this.updatedAt = data.updatedAt;
            this.type = data.type;
            if (Array.isArray(data.substractedAccounts)) {
                this.substractedAccounts = data.substractedAccounts;
            }
            if (Array.isArray(data.addedAccounts)) {
                this.addedAccounts = data.addedAccounts;
            }
            this.name = data.name;
            this.description = data.description;
            this.operation = data.operation;
            this.includeInSubGraphique = data.includeInSubGraphique;
            this.isVisible = data.isVisible;
            this.showAsGraphic = data.showAsGraphic;
            this.previousPeriod = data.previousPeriod;
            this.isPercentReference2 = data.isPercentReference2;
        }
    }
}
