import {Dashboard} from "./dashboard";

export class Company {
    privateKey = "";
    id = null;
    name = "";
    dashboards: Dashboard[] = [];
    select = false

    constructor(data = null) {
        if (data !== null) {
            this.privateKey = data.privateKey;
            this.id = data.id;
            this.name = data.name;
            if (data.hasOwnProperty("dashboards")) {
                for (let i = 0; i < data.dashboards.length; i++) {
                    const element = data.dashboards[i];
                    this.dashboards.push(new Dashboard(element));
                }
            }
        }
    }
}
