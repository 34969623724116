import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {HttpService} from "./http.service";
import {Forecast} from "../models/forecast";


@Injectable({
    providedIn: 'root'
})
export class ForecastService {

    forecast: BehaviorSubject<Forecast>;
    forecasts: BehaviorSubject<Array<Forecast>>;

    constructor(private httpService: HttpService) {
        this.forecast = new BehaviorSubject<Forecast>(new Forecast());
        this.forecasts = new BehaviorSubject<Array<Forecast>>([]);
    }

    /**
     *
     * @param privateKey
     */
    getForecast(privateKey: string): Observable<Forecast> {

        return this.httpService.get("forecast", privateKey).pipe(
            map((res: any) => {
                const forecast: Forecast = new Forecast(res);
                this.forecast.next(forecast);
                return this.forecast.getValue();
            }));
    }

    /**
     *
     * @param companyPk
     */
    getForecasts(companyPk: string): Observable<Array<Forecast>> {

        return this.httpService.get("forecast/list", companyPk).pipe(
            map((res: any) => {
                const forecasts: Array<Forecast> = []
                for (let forecast of res) {
                    forecasts.push(new Forecast(forecast))
                }
                this.forecasts.next(forecasts);
                return this.forecasts.getValue();
            }));
    }

    /**
     *
     * @param companyPk
     * @param data
     */
    create(companyPk: string, data: Forecast): Observable<Forecast> {

        return this.httpService.post("forecast", data, companyPk).pipe(
            map((res: any) => {
                const forecast: Forecast = new Forecast(res);
                this.forecast.next(forecast);
                return this.forecast.getValue();
            }));
    }

    /**
     *
     * @param data
     */

    update(data: Forecast): Observable<Forecast> {

        return this.httpService.patch("forecast", data, data.privateKey).pipe(
            map((res: any) => {
                const forecast: Forecast = new Forecast(res);
                this.forecast.next(forecast);
                return this.forecast.getValue();
            }));
    }

    /**
     *
     * @param privateKey
     */
    delete(privateKey: string): Observable<any> {

        return this.httpService.delete("forecast", privateKey).pipe(
            map((res: any) => {
                const forecasts: Array<Forecast> = this.forecasts.getValue()
                for (let i = 0; i < forecasts.length; i++) {
                    if (forecasts[i].privateKey === privateKey) {
                        forecasts.splice(i, 1);
                        break
                    }
                }
                this.forecasts.next(forecasts);
                return res;
            }));
    }

}
