import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {RouteReuseStrategy} from "@angular/router";
import {IonicModule, IonicRouteStrategy} from "@ionic/angular";
import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {DataService} from "./shared/services/data.service";
import {AuthGuard} from "./shared/guards/auth.guard";
import {EventService} from "./shared/services/event.service";
import {HttpService} from "./shared/services/http.service";
import {AppHelper} from "./shared/helpers/app.helper";
import {HttpClientModule} from "@angular/common/http";
import {LoadGuard} from "./shared/guards/load.guard";
import {Charts} from "./shared/services/chart.servive";
import {StringReader} from "./shared/helpers/stringReader.helper";
import {AppProfils} from "src/app.profils";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {UserService} from "./shared/services/user.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CompanyService} from "./shared/services/company.service";
import {WorkspaceService} from "./shared/services/workspace.service";
import {DashboardService} from "./shared/services/dashboard.service";
import {ComptabilityService} from "./shared/services/comptability.service";
import {DistributionService} from "./shared/services/distribution.service";
import {ForecastService} from "./shared/services/forecast.service";
import {ImportService} from "./shared/services/import.service";
import {ProvisionService} from "./shared/services/provision.service";
import {SubscriptionService} from "./shared/services/subscription.service";
import {NgChartsConfiguration, NgChartsModule} from "ng2-charts";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgChartsModule,
        BrowserAnimationsModule,
    ],
    providers: [
        DataService,
        EventService,
        AppHelper,
        AppProfils,
        StringReader,
        HttpService,
        UserService,
        CompanyService,
        WorkspaceService,
        DashboardService,
        ComptabilityService,
        DistributionService,
        ForecastService,
        ImportService,
        ProvisionService,
        SubscriptionService,
        Charts,
        LoadGuard,
        AuthGuard,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: NgChartsConfiguration, useValue: {}}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
