import {Company} from "src/app/shared/models/company";
import {Group} from "./group";

export class AssocCompaniesGroups {
    privateKey: string = "";
    id: string = "";
    company_id: number = -1;
    group_id: number = -1;
    company = null;
    group = null;

    constructor(data: any = null) {
        if (data !== null) {
            this.id = data.id;
            this.privateKey = data.privateKey;
            this.company_id = data.company_id;
            this.group_id = data.group_id;
            if (data.hasOwnProperty("group")) {
                this.group = new Group(data.group);
            }
            if (data.hasOwnProperty("company")) {
                this.company = new Company(data.company);
            }
        }
    }
}
