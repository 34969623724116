export class ImportField {
    id = null;
    type = "";
    delimit = {type: "", length: 0, separator: ""};
    outputFormat = {format: "", ascii: 32, char: " ", direction: "", length: 0};
    journalOptions = {defaultValue: ""};
    select = false;
    value = "";
    trueValue = "D";
    falseValue = "C";
    fields = [];

    constructor(data: any = null) {
        if (data !== null) {
            this.id = data.id;
            if (data.hasOwnProperty("type")) {
                this.type = data.type;
            }
            if (data.hasOwnProperty("delimit")) {
                this.delimit = Object.assign(this.delimit, data.delimit);
            }
            if (data.hasOwnProperty("outputFormat")) {
                this.outputFormat = Object.assign(this.outputFormat, data.outputFormat);
            }
            if (data.hasOwnProperty("journalOptions")) {
                this.journalOptions = Object.assign(this.journalOptions, data.journalOptions);
            }
            if (data.hasOwnProperty("value")) {
                this.value = data.value;
            }
            if (data.hasOwnProperty("trueValue")) {
                this.trueValue = data.trueValue;
            }
            if (data.hasOwnProperty("falseValue")) {
                this.falseValue = data.falseValue;
            }
            if (data.hasOwnProperty("fields")) {
                this.fields = data.fields;
            }
        }
    }
}
