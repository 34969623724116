export class Provision {
    id = null;
    privateKey = "";
    createdAt = "";
    updatedAt: "";
    compte: string = "";
    credit: any = 0;
    dateStart: string = new Date().toISOString();
    dateEnd: string = new Date().toISOString();
    debit: any = 0;
    value: any = 0;
    isSpread: boolean = false;
    wording: string = "";
    type: string = "Charges";
    select: boolean = false;
    length: number;
    isDebit: boolean = false;

    constructor(data = null) {
        if (data !== null) {
            this.id = data.id;
            this.privateKey = data.privateKey;
            this.createdAt = data.createdAt;
            this.updatedAt = data.updatedAt;
            this.type = data.type;
            this.dateStart = data.dateStart;
            this.dateEnd = data.dateEnd;
            this.compte = data.compte;
            this.credit = data.credit;
            this.debit = data.debit;

            if (this.debit !== 0) {
                this.value = this.debit;
                this.isDebit = true;
            } else {
                this.value = this.credit;
            }
            this.isSpread = data.isSpread;
            this.wording = data.wording;
        }
    }
}
