import {NgModule} from "@angular/core";
import {AuthGuard} from "./shared/guards/auth.guard";
import {PreloadAllModules, RouterModule, Routes} from "@angular/router";
import {LoadGuard} from "./shared/guards/load.guard";

const routes: Routes = [
    /*  {
      path: "",
      redirectTo: "main",
      pathMatch: "full",
    },*/
    {
        canActivate: [AuthGuard],
        canLoad: [LoadGuard],
        path: "",
        loadChildren: () =>
            import("./main/main.module").then((m) => m.MainPageModule),
    },
    {
        path: "auth",
        loadChildren: () =>
            import("./auth/auth.module").then((m) => m.AuthPageModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, useHash: true
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
