import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {HttpService} from "./http.service";
import {Dashboard} from "../models/dashboard";


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    dashboard: BehaviorSubject<Dashboard>;
    dashboards: BehaviorSubject<Array<Dashboard>>;

    constructor(private httpService: HttpService) {
        this.dashboard = new BehaviorSubject<Dashboard>(new Dashboard());
        this.dashboards = new BehaviorSubject<Array<Dashboard>>([]);
    }

    /**
     *
     */
    getDashboards(companyPk): Observable<Array<Dashboard>> {

        return this.httpService.get("dashboard/list", companyPk).pipe(
            map((res: any) => {
                const dashboards: Array<Dashboard> = [];
                for (const dashboard of res) {
                    dashboards.push(new Dashboard(dashboard));
                }
                this.dashboards.next(dashboards);
                return this.dashboards.getValue();
            }));
    }

    getDashboard(privateKey): Observable<Dashboard> {

        return this.httpService.get("dashboard", privateKey).pipe(
            map((res: any) => {
                const dashboard: Dashboard = new Dashboard(res);
                this.dashboard.next(dashboard);
                return this.dashboard.getValue();
            }));
    }

    create(companyPk: string, data: Dashboard): Observable<Dashboard> {

        return this.httpService.post("dashboard", data, companyPk).pipe(
            map((res: any) => {
                const dashboard: Dashboard = new Dashboard(res);
                this.dashboard.next(dashboard);
                return this.dashboard.getValue();
            }));
    }

    update(data: Dashboard): Observable<Dashboard> {

        return this.httpService.patch("dashboard", data, data.privateKey).pipe(
            map((res: any) => {
                const dashboard: Dashboard = new Dashboard(res);
                this.dashboard.next(dashboard);
                return this.dashboard.getValue();
            }));
    }

    delete(privateKey: string): Observable<any> {

        return this.httpService.delete("dashboard", privateKey).pipe(
            map((res: any) => {
                const dashboards: Array<Dashboard> = this.dashboards.getValue()
                for (let i = 0; i < dashboards.length; i++) {
                    if (dashboards[i].privateKey === privateKey) {
                        dashboards.splice(i, 1);
                        break
                    }
                }
                this.dashboards.next(dashboards);
                return res;
            }));
    }
}
