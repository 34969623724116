export class Distribution {
    id = null;
    privateKey = "";
    createdAt = "";
    updatedAt: "";
    root = "";
    type = 1;
    count = 0;
    select = false;

    constructor(data = null) {
        if (data !== null) {
            this.id = data.id;
            this.privateKey = data.privateKey;
            this.createdAt = data.createdAt;
            this.updatedAt = data.updatedAt;
            this.root = data.root;
            this.type = data.type;


        }
    }
}
