import {Injectable, NgZone} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable()
export class HttpService {
    host: string = environment.host;

    constructor(
        private router: Router,
        private zone: NgZone,
        public route: ActivatedRoute,
        private http: HttpClient
    ) {


        /*	if (!isDevMode()) {
                this.host = environment.host;
            }*/
    }

    private _token: string = "";

    get token(): string {
        return this._token;
    }

    set token(value: string) {
        this._token = value;
        localStorage.setItem("token", value);
    }

    //lecture
    get(action, privateKey = ""): Observable<any> {

        const subject = new Subject();
        this.zone.runOutsideAngular(() => {
            this.http
                .get<any>(this.url(action, privateKey), {headers: this.header()})
                .subscribe(
                    (result) => {
                        this.success(subject, result);
                    },
                    (error) => {
                        this.error(subject, error);
                    }
                );
        });
        return subject.asObservable();
    }

    //l'ajout
    post(action, data, privateKey = ""): Observable<any> {

        const subject = new Subject();
        this.zone.runOutsideAngular(() => {
            this.http
                .post<any>(this.url(action, privateKey), data, {
                    headers: this.header(),
                })
                .subscribe(
                    (result) => {
                        this.success(subject, result);
                    },
                    (error) => {
                        this.error(subject, error);
                    }
                );
        });
        return subject.asObservable();
    }

    // supprime
    delete(action, privateKey = ""): Observable<any> {

        const subject = new Subject();
        this.zone.runOutsideAngular(() => {
            this.http
                .delete<any>(this.url(action, privateKey), {headers: this.header()})
                .subscribe(
                    (result) => {
                        this.success(subject, result);
                    },
                    (error) => {
                        this.error(subject, error);
                    }
                );
        });
        return subject.asObservable();
    }

    // mise a jour
    patch(action, data, privateKey = ""): Observable<any> {

        const subject = new Subject();
        this.zone.runOutsideAngular(() => {
            this.http
                .patch<any>(this.url(action, privateKey), data, {
                    headers: this.header(),
                })
                .subscribe(
                    (result) => {
                        this.success(subject, result);
                    },
                    (error) => {
                        this.error(subject, error);
                    }
                );
        });
        return subject.asObservable();
    }

    /****************************************************************/
    url(action, privateKey = "") {

        let url = this.host + action + "?cache:" + new Date().getTime();
        if (privateKey !== "") {
            url =
                this.host +
                action +
                "/" +
                privateKey +
                "?cache:" +
                new Date().getTime();
        }
        return url;
    }

    header() {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.token,
        });

        return headers;
    }

    success(subject, result) {

        this.zone.run(() => subject.next(result));
        subject.complete();
    }

    error(subject, error) {

        console.error(error.message);
        switch (error.status) {
            case 401:
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                this.router.navigate(["/auth/signin"]);
                break;
            case 404:
                this.zone.run(() => subject.error(error.message));
                break;
            case 413:
                this.zone.run(() => subject.error(error.message));
                break;
            default:
                this.zone.run(() => subject.error(error.error.message));
                break;
        }
        subject.complete();
    }
}
