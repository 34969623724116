import {Injectable} from "@angular/core";
import Printd from "printd";
import {WorkspaceService} from "../services/workspace.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DashboardService} from "../services/dashboard.service";
import {CompanyService} from "../services/company.service";

@Injectable()
export class AppHelper {
    dashbordPk: string;
    companyPk: string;
    workspacePk: string;
    type: string;

    constructor(private workspaceService: WorkspaceService,
                private companyService: CompanyService,
                private dashboardService: DashboardService,
                private route: ActivatedRoute,
                private router: Router) {


        const url = location.href;
        const params: any = url.split("/");

        this.workspacePk = params[4];
        this.type = params[5];
        this.companyPk = params[6];
        this.dashbordPk = params[8];
    }

    public async initApp() {

        if (this.workspacePk !== undefined) {
            await this.workspaceService.setCurrentWorkspace(this.workspacePk).toPromise();
        }
        if (this.companyPk !== undefined) {
            await this.companyService.getCompany(this.companyPk).toPromise();
        }
        if (this.dashbordPk !== undefined) {
            await this.dashboardService.getDashboard(this.dashbordPk).toPromise();
        }
        await this.workspaceService.getWorkspaces().toPromise();

        return true;
    }

    public monthDiff(d1, d2) {
        const dt1 = new Date(d1);
        const dt2 = new Date(d2);
        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60 * 60 * 24 * 7 * 4;
        return Math.abs(Math.round(diff));
    }

    public formatDate(value): string {
        if (typeof value !== "undefined") {
            const date = new Date(
                value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3")
            );
            const d = date.getDate();
            const m = date.getMonth() + 1;
            const y = date.getFullYear();

            const dateFinal =
                "" + (d <= 9 ? "0" + d : d) + "/" + (m <= 9 ? "0" + m : m) + "/" + y;
            return dateFinal;
        } else {
            return "";
        }
    }

    public nonbreJourMois(mois, annee) {
        const nbreJour = new Date(annee, mois, -1).getDate() + 1;
        return nbreJour;
    }

    public arrayMove(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    }

    public formatMonnay(value, arrond = 0) {
        if (typeof value === "string") {
            if (value === " " || value.indexOf("/") !== -1) {
                return value;
            }
        }


        let result = value;

        if ("Cent" === "Cent") {
            result = Math.round(result * 100) / 100;
            if (result === 0) {
                result = "0.00";
            }
        } else {
            result = Math.round(result);
        }

        result = String(result).replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        return result.replace(".", ",");
    }

    public stringToNumber(value: string): number {
        value = String(value).replace(",", ".");
        return Number(Number(value).toFixed(2));
    }

    public print(tableID, name, graphClass = null) {
        const company = JSON.parse(localStorage.getItem("company"));
        const tableSelect = document.getElementById(tableID);
        const title = document.getElementById("printTitle");
        const graphs = document.getElementsByClassName(graphClass);

        const elem = document.createElement("html");

        // let windowContent = "<!DOCTYPE html>";
        let windowContent = "";
        // windowContent += "<html>";
        windowContent += "<head><title>Print canvas</title></head>";
        windowContent += "<body style='-webkit-print-color-adjust:exact;'>";

        if (title !== null) {
            windowContent += "<div>" + title.outerHTML + "</div>";
        }
        if (tableSelect !== null) {
            windowContent += tableSelect.outerHTML;
        }

        Array.prototype.forEach.call(graphs, function (el) {
            if (el.attributes.hasOwnProperty("title")) {
                windowContent += "<span>" + el.attributes.title.nodeValue + "</span>";
            }
            windowContent += "<img src='" + el.toDataURL() + "'/>";
        });

        windowContent += "<div>";
        windowContent +=
            "Reporting -- " + new Date().toLocaleDateString() + " -- " + name;
        windowContent += "</div>";
        windowContent += "</body>";
        //		windowContent += "</html>";

        elem.innerHTML = windowContent;
        const d = new Printd();
        d.print(elem, []);
        /*	const newWin = window.open("");
                newWin.document.write(windowContent);
                    newWin.print();
                    newWin.close();*/
    }

    public exportTableToExcel(tableID, filename = "") {
        let downloadLink;
        const dataType = "application/vnd.ms-excel";
        const tableSelect = document.getElementById(tableID);
        if (tableSelect === null) {
            return;
        }
        const tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

        // Specify file name
        filename = filename ? filename + ".xls" : "excel_data.xls";

        // Create download link element
        downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        const nav: any = window.navigator
        if (nav.msSaveOrOpenBlob) {
            const blob = new Blob(["\ufeff", tableHTML], {
                type: dataType,
            });
            nav.msSaveOrOpenBlob(blob, filename);
        } else {
            // Create a link to the file
            downloadLink.href = "data:" + dataType + ", " + tableHTML;

            // Setting the file name
            downloadLink.download = filename;

            // triggering the function
            downloadLink.click();
        }
    }

    public ObjectDecode(str): Object {
        let j;
        const hexes = str.match(/.{1,4}/g) || [];
        let back = "";
        for (j = 0; j < hexes.length; j++) {
            back += String.fromCharCode(parseInt(hexes[j], 16));
        }

        return JSON.parse(back);
    }

    public ObjectEncode(object): String {
        const str = JSON.stringify(object);

        let hex, i;

        let result = "";
        for (i = 0; i < str.length; i++) {
            hex = str.charCodeAt(i).toString(16);
            result += ("000" + hex).slice(-4);
        }

        return result;
    }

    public slugify(str) {
        const map = {
            "-": " ",
            a: "á|à|ã|â|À|Á|Ã|Â",
            e: "é|è|ê|É|È|Ê",
            i: "í|ì|î|Í|Ì|Î",
            o: "ó|ò|ô|õ|Ó|Ò|Ô|Õ",
            u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
            c: "ç|Ç",
            n: "ñ|Ñ",
        };

        str = str.toLowerCase();

        for (const pattern in map) {
            if (map.hasOwnProperty(pattern)) {
                str = str.replace(new RegExp(map[pattern], "g"), pattern);
            }
        }

        return str;
    }

    public getDateArray(start, end) {
        const arr = [];
        const dt = new Date(start);

        while (dt <= end) {
            arr.push(new Date(dt));
            dt.setDate(dt.getDate() + 1);
        }

        return arr;
    }

    public getDateArrayMonth(start, end) {
        const arr = [];
        const dt = new Date(start);

        while (dt <= end) {
            const date = new Date(dt);
            arr.push({
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                select: false,
            });
            dt.setMonth(dt.getMonth() + 1);
        }

        return arr;
    }
}
