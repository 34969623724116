import {Injectable} from "@angular/core";

@Injectable()
export class DataService {
    public token = "";
    public showView = 0;
    public showType = 1;
    public comparatif = 1;
    public options = {
        addProvision: false,
        addRepartition: false,
        addAbonnement: false,
        isExercice: true,
        isPeriode: false,
        currentExercice: 2018,
        currentMonth: "",
        currentYear: "",
        maxMonth: 12,
        comparatif: 1,
        type: 0,
    };

    constructor() {
    }
}
