import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {HttpService} from "./http.service";
import {Distribution} from "../models/distribution";


@Injectable({
    providedIn: 'root'
})
export class DistributionService {

    distribution: BehaviorSubject<Distribution>;
    distributions: BehaviorSubject<Array<Distribution>>;

    constructor(private httpService: HttpService) {
        this.distribution = new BehaviorSubject<Distribution>(new Distribution());
        this.distributions = new BehaviorSubject<Array<Distribution>>([]);
    }

    /**
     *
     */
    getDistributions(companyPk: string): Observable<Array<Distribution>> {

        return this.httpService.get("distribution/list/", companyPk).pipe(
            map((res: any) => {
                const distributions: Array<Distribution> = []
                for (let distribution of res) {
                    distributions.push(new Distribution(distribution))
                }
                this.distributions.next(distributions);
                return this.distributions.getValue();
            }));
    }

    create(companyPk: string, data: Distribution): Observable<Distribution> {

        return this.httpService.post("distribution", data, companyPk).pipe(
            map((res: any) => {
                const distribution: Distribution = new Distribution(res);
                this.distribution.next(distribution);
                return this.distribution.getValue();
            }));
    }

    update(data: Distribution): Observable<Distribution> {

        return this.httpService.patch("distribution", data, data.privateKey).pipe(
            map((res: any) => {
                const distribution: Distribution = new Distribution(res);
                this.distribution.next(distribution);
                return this.distribution.getValue();
            }));
    }

    delete(privateKey: string): Observable<any> {

        return this.httpService.delete("distribution", privateKey).pipe(
            map((res: any) => {
                const distributions: Array<Distribution> = this.distributions.getValue()
                for (let i = 0; i < distributions.length; i++) {
                    if (distributions[i].privateKey === privateKey) {
                        distributions.splice(i, 1);
                        break
                    }
                }
                this.distributions.next(distributions);
                return res;
            }));
    }

}
