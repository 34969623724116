import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {HttpService} from "./http.service";
import {Import} from "../models/import";


@Injectable({
    providedIn: 'root'
})
export class ImportService {

    import: BehaviorSubject<Import>;
    imports: BehaviorSubject<Array<Import>>;

    constructor(private httpService: HttpService) {
        this.import = new BehaviorSubject<Import>(new Import());
        this.imports = new BehaviorSubject<Array<Import>>([]);
    }

    /**
     *
     * @param privateKey
     */
    getImport(privateKey: string): Observable<Import> {

        return this.httpService.get("import/", privateKey).pipe(
            map((res: any) => {
                const imp: Import = new Import(res);
                this.import.next(imp);
                return this.import.getValue();
            }));
    }

    /**
     *
     * @param workspacePk
     */
    getImports(workspacePk: string): Observable<Array<Import>> {

        return this.httpService.post("import/byWorkspace", {privateKey: workspacePk}).pipe(
            map((res: any) => {
                const imports: Array<Import> = []
                for (let imp of res) {
                    imports.push(new Import(imp))
                }
                this.imports.next(imports);
                return this.imports.getValue();
            }));
    }

    /**
     *
     * @param workspacePk
     * @param data
     */
    create(workspacePk: string, data: Import): Observable<Import> {

        return this.httpService.post("import/create", {
            workspacePk: workspacePk,
            profile: data
        }).pipe(
            map((res: any) => {
                const imp: Import = new Import(res);
                this.import.next(imp);
                return this.import.getValue();
            }));
    }

    /**
     *
     * @param data
     */
    createDefault(data: Import): Observable<Import> {

        return this.httpService.post("import/createDefault", data).pipe(
            map((res: any) => {
                const imp: Import = new Import(res);
                return imp;
            }));
    }

    getDefault(): Observable<any> {
        return this.httpService.post("import/getDefault", {}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    /**
     *
     * @param data
     */

    update(data: Import): Observable<Import> {

        return this.httpService.post("import/update", data).pipe(
            map((res: any) => {
                const imp: Import = new Import(res);
                this.import.next(imp);
                return this.import.getValue();
            }));
    }

    /**
     *
     * @param privateKey
     */
    delete(privateKey: string): Observable<any> {

        return this.httpService.delete("import", privateKey).pipe(
            map((res: any) => {
                const imports: Array<Import> = this.imports.getValue()
                for (let i = 0; i < imports.length; i++) {
                    if (imports[i].privateKey === privateKey) {
                        imports.splice(i, 1);
                        break
                    }
                }
                this.imports.next(imports);
                return res;
            }));
    }

    /**
     *
     * @param companyPk
     * @param accounts
     * @param period
     */
    importData(companyPk: string, accounts: Array<any>, period: string): Observable<Import> {

        return this.httpService.post("import/import", {
                companyPk: companyPk,
                accounts: accounts,
                period: period
            }
        );
    }

}
