import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {HttpService} from "./http.service";
import {Provision} from "../models/provision";


@Injectable({
    providedIn: 'root'
})
export class ProvisionService {

    provision: BehaviorSubject<Provision>;
    provisions: BehaviorSubject<Array<Provision>>;

    constructor(private httpService: HttpService) {
        this.provision = new BehaviorSubject<Provision>(new Provision());
        this.provisions = new BehaviorSubject<Array<Provision>>([]);
    }

    /**
     *
     */
    getProvisions(companyPk: string): Observable<Array<Provision>> {

        return this.httpService.get("provision/list/", companyPk).pipe(
            map((res: any) => {
                const provisions: Array<Provision> = []
                for (let provision of res) {
                    provisions.push(new Provision(provision))
                }
                this.provisions.next(provisions);
                return this.provisions.getValue();
            }));
    }

    create(companyPk: string, data: any): Observable<Provision> {

        return this.httpService.post("provision", data, companyPk).pipe(
            map((res: any) => {
                const provision: Provision = new Provision(res);
                this.provision.next(provision);
                return this.provision.getValue();
            }));
    }

    update(data: any): Observable<Provision> {

        return this.httpService.patch("provision", data, data.privateKey).pipe(
            map((res: any) => {
                const provision: Provision = new Provision(res);
                this.provision.next(provision);
                return this.provision.getValue();
            }));
    }

    delete(privateKey: string): Observable<any> {

        return this.httpService.delete("provision", privateKey).pipe(
            map((res: any) => {
                const provisions: Array<Provision> = this.provisions.getValue()
                for (let i = 0; i < provisions.length; i++) {
                    if (provisions[i].privateKey === privateKey) {
                        provisions.splice(i, 1);
                        break
                    }
                }
                this.provisions.next(provisions);
                return res;
            }));
    }

}
