import {Injectable} from "@angular/core";
import {Route, Router} from "@angular/router";
import {DataService} from "../services/data.service";
import {AppHelper} from "../helpers/app.helper";
import {HttpService} from "../services/http.service";
import {UserService} from "../services/user.service";
import {User} from "../models/user";

@Injectable()
export class LoadGuard {
    i = 0;
    isFinish = false;

    constructor(
        private router: Router,
        private helpers: AppHelper,
        private datas: DataService,
        private userService: UserService,
        private httpService: HttpService
    ) {
    }

    finish(): Promise<boolean> {


        const result: Promise<boolean> = new Promise((resolve) => {
            if (this.userService.userAuth.getValue() === null) {
                this.router.navigate(["/auth/signin"]);
                resolve(true);
                this.isFinish = true;
            } else if (this.router.url === "/auth/signin") {
                resolve(true);
                this.isFinish = true;
            } else {
                if (!this.isFinish) {
                    this.isFinish = true;
                    this.helpers.initApp().then((x) => {
                        resolve(true);
                    });
                } else {
                    resolve(true);
                }
            }
        });

        return result;
    }

    canLoad(route: Route): Promise<boolean> {

        const token = localStorage.getItem('token');
        const user = new User(JSON.parse(localStorage.getItem('user')));
        if (token) {
            this.httpService.token = token;
        }
        if (user) {
            this.userService.userAuth.next(user);
        }
        if (this.isFinish) {
            return new Promise((resolve) => {
                resolve(true);
            });
        }
        const result: Promise<boolean> = new Promise((resolve) => {
            this.i++;
            this.userService.userAuth.next(JSON.parse(localStorage.getItem("user")));
            this.httpService.token = localStorage.getItem("token");
            resolve(this.finish());
        });

        return result;
    }
}
