import {AssocCompaniesGroups} from "./assoc-companies-groups";

export class Group {
    privateKey: string = "";
    id: string = "";
    name: string = "";
    computeMode = "";
    lastcomptaimportdate = "";
    lastComptaDate = "";
    isAvailableForFranchises: boolean = false;
    isAvailableForAnimateurs: boolean = false;
    select: boolean = false;
    isCompany: boolean = false;
    parentId: any = -1;
    countCompany: number = 0;
    children?: Group[] = [];
    assocCompaniesGroups?: AssocCompaniesGroups[] = [];

    constructor(data: any = null) {
        if (data !== null) {
            this.id = data.id;
            this.privateKey = data.privateKey;
            this.name = data.name;
            this.children = [];
            if (data.hasOwnProperty("children")) {
                for (let i = 0; i < data.children.length; i++) {
                    const element = data.children[i];
                    this.children.push(new Group(element));
                }
            }
            if (data.hasOwnProperty("assoc_companies_groups")) {
                this.countCompany = data.assoc_companies_groups.length;
                for (let i = 0; i < data.assoc_companies_groups.length; i++) {
                    const element = data.assoc_companies_groups[i];
                    this.assocCompaniesGroups.push(new AssocCompaniesGroups(element));
                }
            }
        }
    }
}
