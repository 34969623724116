import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {HttpService} from "./http.service";
import {Workspace} from "../models/workspace";
import {Company} from "../models/company";


@Injectable({
    providedIn: 'root'
})
export class WorkspaceService {

    workspace: BehaviorSubject<Workspace>;
    workspaces: BehaviorSubject<Array<Workspace>>;

    constructor(private httpService: HttpService) {
        this.workspace = new BehaviorSubject<Workspace>(new Workspace());
        this.workspaces = new BehaviorSubject<Array<Workspace>>([]);
    }

    /**
     *
     */
    getWorkspaces(): Observable<Array<Workspace>> {

        return this.httpService.get("workspace").pipe(
            map((res: any) => {
                const workspaces: Array<Workspace> = [];
                for (const workspace of res) {
                    workspaces.push(new Workspace(workspace));
                }
                this.workspaces.next(workspaces);
                return this.workspaces.getValue();
            }));
    }

    createWorkspace(workspace: Workspace): Observable<Workspace> {

        return this.httpService.post("workspace", workspace).pipe(
            map((res: any) => {
                const workspace: Workspace = new Workspace(res);
                this.workspace.next(workspace);
                return this.workspace.getValue();
            }));
    }

    setCurrentWorkspace(privateKey: string): Observable<Workspace> {

        return this.httpService.post("workspace/setCurrent", {pk: privateKey}).pipe(
            map((res: any) => {
                this.httpService.token = res.token;
                this.workspace.next(new Workspace(res.workspace));
                return this.workspace.getValue();
            }));
    }

}
