export class StringReader {
    /*Read(arg0: number): any {
        throw new Error("Method not implemented.");
    }*/
    index: number = 0;
    string: string = "";

    constructor(string: string = "") {
        this.string = string;
    }

    read(index: number): string {
        const newIndex = this.index + index;
        const result = this.string.slice(this.index, newIndex);
        this.index = newIndex;
        return result;
    }

    readBySeparator(separator: string): string {
        let result = "";
        for (let i = this.index; i < this.string.length; i++) {
            if (this.string[i] === separator) {
                this.index = i + 1;
                break;
            }
            result += this.string[i];
        }
        return result;
    }

    readBySize(size: number): string {
        const newIndex = this.index + size;
        const result = this.string.slice(this.index, newIndex);
        this.index = newIndex;
        return result;
    }

    readByTabulation(): string {
        let result = "";
        for (let i = this.index; i < this.string.length; i++) {
            if (this.string[i] === "\\") {
                if (this.string.length > i + 1 && this.string[i + 1] === "t") {
                    this.index = i + 2;
                    break;
                }
                this.index = i + 1;
                break;
            }
            result += this.string[i];
        }
        return result;
    }

    readEnd(): string {
        const newIndex = this.index + this.string.length - 1;
        const result = this.string.slice(this.index, newIndex);
        this.index = newIndex;
        return result;
    }

    length(): number {
        return this.string.length;
    }

    startsWith(char: string): boolean {
        if (this.string.slice(0, char.length) === char) {
            return true;
        }
        return false;
    }
}
