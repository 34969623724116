import {Item} from "./Item";

export class Dashboard {
    id: number = null;
    privateKey = "";
    createdAt: string = null;
    updatedAt: string = null;
    name: string = "";
    mustCorrespondToOperatingAccount: boolean = false;
    restrictCurrentCompany: boolean = false;
    showTotal: boolean = false;
    items: Item[] = [];
    select: boolean = false;

    /***************/
    companyId: string = "";

    constructor(data: any = null) {
        if (data !== null) {
            this.id = data.id;
            this.privateKey = data.privateKey;
            this.name = data.name;
            this.createdAt = data.createdAt;
            this.updatedAt = data.updatedAt;
            this.mustCorrespondToOperatingAccount =
                data.mustCorrespondToOperatingAccount;
            this.restrictCurrentCompany = data.restrictCurrentCompany;
            this.showTotal = data.showTotal;
            this.items = [];
            if (data.hasOwnProperty("items")) {
                for (let i = 0; i < data.items.length; i++) {
                    const item = data.items[i];
                    this.items.push(new Item(item));
                }
            }
        }
    }
}
