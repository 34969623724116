export class User {
    id: number = null;
    createdAt: string = null;
    updatedAt: string = null;
    manager: boolean = false;
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    password: string = "";
    confirmPassword: string = "";
    right: string[] = ["AllRights"];
    civility: string = "";
    networks = [];
    famillies = [];
    myCompanies = [];

    constructor(data = null) {
        if (data !== null) {
            this.id = data.id;
            this.createdAt = data.createdAt;
            this.updatedAt = data.updatedAt;
            this.manager = data.manager;
            this.firstName = data.firstName;
            this.lastName = data.lastName;
            this.email = data.email;
            this.password = data.password;
            this.right = data.right;
            this.civility = data.civility;
            this.networks = data.networks;
            this.famillies = data.famillies;
            this.myCompanies = data.myCompanies;
        }
    }
}
