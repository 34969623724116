import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {HttpService} from "./http.service";
import {Subscription} from "../models/subscription";


@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    subscription: BehaviorSubject<Subscription>;
    subscriptions: BehaviorSubject<Array<Subscription>>;

    constructor(private httpService: HttpService) {
        this.subscription = new BehaviorSubject<Subscription>(new Subscription());
        this.subscriptions = new BehaviorSubject<Array<Subscription>>([]);
    }

    /**
     *
     */
    getSubscriptions(companyPk: string): Observable<Array<Subscription>> {

        return this.httpService.get("subscription/list/", companyPk).pipe(
            map((res: any) => {
                const subscriptions: Array<Subscription> = []
                for (let subscription of res) {
                    subscriptions.push(new Subscription(subscription))
                }
                this.subscriptions.next(subscriptions);
                return this.subscriptions.getValue();
            }));
    }

    create(companyPk: string, data: any): Observable<Subscription> {

        return this.httpService.post("subscription", data, companyPk).pipe(
            map((res: any) => {
                const subscription: Subscription = new Subscription(res);
                this.subscription.next(subscription);
                return this.subscription.getValue();
            }));
    }

    update(data: any): Observable<Subscription> {

        return this.httpService.patch("subscription", data, data.privateKey).pipe(
            map((res: any) => {
                const subscription: Subscription = new Subscription(res);
                this.subscription.next(subscription);
                return this.subscription.getValue();
            }));
    }

    delete(privateKey: string): Observable<any> {

        return this.httpService.delete("subscription", privateKey).pipe(
            map((res: any) => {
                const subscriptions: Array<Subscription> = this.subscriptions.getValue()
                for (let i = 0; i < subscriptions.length; i++) {
                    if (subscriptions[i].privateKey === privateKey) {
                        subscriptions.splice(i, 1);
                        break
                    }
                }
                this.subscriptions.next(subscriptions);
                return res;
            }));
    }

}
