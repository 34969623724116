import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {HttpService} from "./http.service";
import {Company} from "../models/company";


@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    company: BehaviorSubject<Company>;
    companies: BehaviorSubject<Array<Company>>;
    exercices: BehaviorSubject<Array<any>>;

    constructor(private httpService: HttpService) {
        this.company = new BehaviorSubject<Company>(new Company());
        this.companies = new BehaviorSubject<Array<Company>>([]);
        this.exercices = new BehaviorSubject<Array<any>>([]);
    }

    /**
     *
     */
    getCompanies(): Observable<Array<Company>> {

        return this.httpService.get("company").pipe(
            map((res: any) => {
                const companies: Array<Company> = [];
                for (const company of res) {
                    companies.push(new Company(company));
                }
                this.companies.next(companies);
                return this.companies.getValue();
            }));
    }

    getCompany(privateKey: string): Observable<Company> {

        return this.httpService.get("company", privateKey).pipe(
            map((res: any) => {
                const company: Company = new Company(res.company);
                localStorage.setItem('exercices', JSON.stringify(res.exercices))
                this.exercices.next(res.exercices);
                this.company.next(company);
                return this.company.getValue();
            }));
    }

    updateCompany(data: Company): Observable<Company> {

        return this.httpService.patch("company", data, data.privateKey).pipe(
            map((res: any) => {
                const company: Company = new Company(res);
                this.company.next(company);
                return this.company.getValue();
            }));
    }

    createCompany(data: Company): Observable<Company> {

        return this.httpService.post("company", data).pipe(
            map((res: any) => {
                const company: Company = new Company(res);
                this.company.next(company);
                return this.company.getValue();
            }));
    }

}
