import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {HttpService} from "./http.service";


@Injectable({
    providedIn: 'root'
})
export class ComptabilityService {


    constructor(private httpService: HttpService) {

    }


    /**
     *
     */
    getGrid(dashboardPk: string, companyPk: string, showType: number, options: object): Observable<any> {

        return this.httpService.get("comptability/getGrid/" + dashboardPk + "/" + companyPk + "/" + showType + "/" + JSON.stringify(options))
            .pipe(map((res: any) => {
                return res;
            }));
    }

    getGridZoom(dashboardPk: string, companyPk: string, showType: number, options: object): Observable<any> {

        return this.httpService.get("comptability/getGridZoom/" + dashboardPk + "/" + companyPk + "/" + showType + "/" + JSON.stringify(options))
            .pipe(map((res: any) => {
                return res;
            }));
    }

    /**
     *
     */
    getChart(dashboardPk: string, companyPk: string, showType: number, options: object): Observable<any> {

        return this.httpService.get("comptability/getChart/" + dashboardPk + "/" + companyPk + "/" + showType + "/" + JSON.stringify(options))
            .pipe(map((res: any) => {
                return res;
            }));
    }

    getChartZoom(dashboardPk: string, companyPk: string, showType: number, options: object): Observable<any> {

        return this.httpService.get("comptability/getChartZoom/" + dashboardPk + "/" + companyPk + "/" + showType + "/" + JSON.stringify(options))
            .pipe(map((res: any) => {
                return res;
            }));
    }

    /**
     *
     */
    getComptaGrid(dashboardPk: string, companyPk: string, options: object): Observable<any> {

        return this.httpService.get("comptability/getComptaGrid/" + dashboardPk + "/" + companyPk + "/" + JSON.stringify(options))
            .pipe(map((res: any) => {
                return res;
            }));
    }

    getAccounts(companyPk: string, options: object): Observable<any> {

        return this.httpService.get("comptability/get-accounts/" + companyPk + "/" + JSON.stringify(options))
            .pipe(map((res: any) => {
                return res;
            }));
    }

    getComparativePerformance(showType: number, options: object): Observable<any> {

        return this.httpService.get("comparative-performance/" + showType + "/" + JSON.stringify(options))
            .pipe(map((res: any) => {
                return res;
            }));
    }
}
