import {ForecastOption} from './forecastOption';
import {ForecastLine} from "./forecastLine";

export class Forecast {
    id = null;
    privateKey = "";
    createdAt = "";
    updatedAt: "";
    select = false;
    data: ForecastLine[] = [];
    dataTemp = [];
    period = null;
    totalPercent: 0;
    produit = 0;
    charge = 0;
    resultat = 0;
    name = "";
    options = new ForecastOption();

    constructor(data = null) {
        if (data !== null) {
            this.id = data.id;
            this.privateKey = data.privateKey;
            this.createdAt = data.createdAt;
            this.updatedAt = data.updatedAt;
            if (data.hasOwnProperty("data")) {
                for (let i = 0; i < data.data.length; i++) {
                    const element = data.data[i];
                    this.data.push(new ForecastLine(element));
                }
            }
            this.period = data.period;
            this.produit = data.produit;
            this.charge = data.charge;
            this.resultat = data.resultat;
            this.options = new ForecastOption(data.options);
            this.name = data.name;
        }
    }
}
