import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpService} from "../services/http.service";

@Injectable()
export class AuthGuard {
    constructor(private router: Router, private httpService: HttpService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (this.httpService.token !== undefined && this.httpService.token !== '') {
            return true;
        }

        this.router.navigate(['/auth/signin']);
        return false;
    }
}

