import {Company} from "./company";
import {Group} from "./group";

export class Workspace {
    id = null;
    privateKey = "";
    name = "";
    activityPreference = {
        amountShowMode: "HT",
        familyDescriptionRule: "Libelle",
        familyDescriptionSeparator: "-",
    };
    createdAt = "";
    devise = "€";
    exerciceMonthStart = "0";
    roundMoneyValue: false;
    updatedAt: "";
    week: "Monday";
    showMode: 'Cent';
    companies: Company[] = [];
    groups: Group[] = [];

    constructor(data = null) {
        if (data !== null) {
            this.id = data.id;
            this.privateKey = data.privateKey;
            this.activityPreference = data.activityPreference;
            this.name = data.name;
            this.createdAt = data.createdAt;
            this.updatedAt = data.updatedAt;
            this.devise = data.devise;
            this.exerciceMonthStart = data.exerciceMonthStart;
            this.roundMoneyValue = data.roundMoneyValue;
            this.week = data.week;
            if (data.hasOwnProperty("assoc_company_workspaces")) {
                for (let i = 0; i < data.assoc_company_workspaces.length; i++) {
                    const element = data.assoc_company_workspaces[i];
                    if (element.hasOwnProperty("company")) {
                        this.companies.push(new Company(element.company));
                    }
                }
            }
            if (data.hasOwnProperty("groups")) {
                for (let i = 0; i < data.groups.length; i++) {
                    const element = data.groups[i];
                    this.groups.push(new Group(element));
                }
            }
        }
    }
}
