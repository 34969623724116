import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from "../models/user";
import {map} from "rxjs/operators";
import {HttpService} from "./http.service";
import {Router, RouterState} from "@angular/router";


@Injectable({
    providedIn: 'root'
})
export class UserService {

    private router : Router = inject(Router);
    userAuth: BehaviorSubject<User>;
    users: BehaviorSubject<Array<User>>;

    constructor(private https: HttpService) {
        this.userAuth = new BehaviorSubject<User>(null);
        this.users = new BehaviorSubject<Array<User>>([]);
    }

    /**
     *
     * @param email
     * @param password
     */
    signIn(email: string, password: string): Observable<any> {
        return this.https.post('login', {email, password}).pipe(
            map((res: any) => {
                this.https.token = res.token
                const user: User = new User(res.user);
                this.userAuth.next(user);
                localStorage.setItem("user", JSON.stringify(res.user));
                return this.userAuth.getValue();
            }));
    }

    /**
     *
     * @param email
     * @param firstName
     * @param lastName
     * @param civility
     * @param password
     * @param confirmPassword
     * @param companyName
     * @param workspaceName
     */
    signUp(firstName: string,
           lastName: string,
           civility: string,
           email: string,
           password: string,
           confirmPassword: string,
           companyName: string,
           workspaceName: string): Observable<any> {
        return this.https.post('register', {
            email,
            firstName,
            lastName,
            civility,
            password,
            right: ["AllRights"],
            confirmPassword,
            companyName,
            workspaceName
        });

    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        this.router.navigate(['/auth/signin']);
    }

    /**
     *
     */
    getUserAuth(): Observable<User> {
        return this.userAuth;
    }
}
