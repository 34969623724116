import {inject, Injectable} from "@angular/core";
import {AppHelper} from "../helpers/app.helper";

@Injectable()
export class Charts {
    options: any = {};
    resultat = {
        data: {
            data: null,
            label: "Résultat",
            type: "bar",
            pointStyle: "rect",
        },
        color: {
            backgroundColor: "rgba(24, 179, 0, 0.3)",
            borderColor: "rgba(24, 179, 0, 1)",
            borderWidth: 2,
        },
    };
    resultatsPro = {
        data: {
            data: null,
            label: "Résultat progressif",
            fill: false,
            lineTension: 0,
            type: "line",
            pointStyle: "line",
        },
        color: {
            borderColor: "rgba(24, 179, 0, 1)",
        },
    };
    produits = {
        data: {
            data: null,
            label: "Produits",
            type: "bar",
            pointStyle: "rect",
        },
        color: {
            backgroundColor: "rgba(23, 117, 255, 0.3)",
            borderColor: "rgba(23, 117, 255, 1)",
            borderWidth: 1,
        },
    };
    produitsCum = {
        data: {
            data: null,
            label: "Produits cumulés",
            fill: false,
            lineTension: 0,
            type: "line",
            pointStyle: "line",
        },
        color: {
            borderColor: "rgba(23, 117, 255, 1)",
        },
    };
    charges = {
        data: {
            data: null,
            label: "Charges",
            type: "bar",
            pointStyle: "rect",
        },
        color: {
            backgroundColor: "rgba(255, 161, 0, 0.3)",
            borderColor: "rgba(255, 161, 0, 1)",
            borderWidth: 2,
        },
    };
    chargesCum = {
        data: {
            data: null,
            label: "Charges cumulés",
            fill: false,
            lineTension: 0,
            type: "line",
            pointStyle: "line",
        },
        color: {
            borderColor: "rgba(255, 161, 0, 1)",
        },
    };
    comparatif = {
        data: {
            data: null,
            label: "",
            type: "bar",
            pointStyle: "rect",
        },
        color: {
            backgroundColor: "var(--reporting-color-10)",
            borderColor: "var(--reporting-color-5)",
            borderWidth: 2,
        },
    };
    comparatifCum = {
        data: {
            data: null,
            label: "",
            fill: false,
            lineTension: 0,
            type: "line",
            pointStyle: "line",
        },
        color: {
            borderColor: "var(--reporting-color-5)",
        },
    };
    private helpers: AppHelper = inject(AppHelper)

    constructor() {

        this.options = {
            responsive: true,
            maintainAspectRatio: false,
            scaleShowVerticalLines: false,
            legend: {
                labels: {
                    usePointStyle: true,
                },
            },
            tooltips: {
                callbacks: {
                    afterLabel: function (tooltipItem, data) {
                        if (tooltipItem.yLabel === "") {
                            let total = 0;
                            for (let i = 0; i < data.datasets[0].data.length; i++) {
                                const element = data.datasets[0].data[i];
                                total += element;
                            }

                            const value = this.helpers.formatMonnay(
                                data.datasets[0].data[tooltipItem.index]
                            );
                            const percente = this.helpers.formatMonnay(
                                (data.datasets[0].data[tooltipItem.index] * 100) / total,
                                2
                            );

                            return percente + "%";
                        }

                        return "";
                    },
                    label: function (tooltipItem, data) {
                        if (tooltipItem.yLabel === "") {
                            const value = this.helpers.formatMonnay(
                                data.datasets[0].data[tooltipItem.index]
                            );
                            return value;
                        }
                        return this.helpers.formatMonnay(tooltipItem.yLabel);
                    },
                },
            },
            scales: {},
        };
    }
}
