export class ForecastOption {
    showSelectTotal = true;
    showSubTotals = true;
    alwaysShowSubTotals = false;
    code = 1

    constructor(data = null) {
        if (data !== null) {
            this.showSelectTotal = data.showSelectTotal;
            this.showSubTotals = data.showSubTotals;
            this.alwaysShowSubTotals = data.alwaysShowSubTotals;
            this.code = data.code;
        }
    }
}
