export class ForecastLine {
    libelle = "";
    numero = "";
    state = 0;
    data = [];
    total = 0;
    totalOld = 0;
    totalPercent = 0;

    select = false;
    isTotal = false;
    edit = false;

    constructor(data = null) {
        if (data !== null) {
            this.libelle = data.libelle;
            this.numero = data.numero;
            this.state = data.state;
            this.data = data.data;
            this.total = data.total;
            this.totalOld = data.totalOld;
            this.totalPercent = data.totalPercent;
            this.edit = data.edit;
            this.isTotal = data.isTotal;
        }
    }
}
